<template>
  <div>
    <div class="container">
      <div class="float_right">
        <p><a @click="()=>{
              contentHtml = detail.rule
              contentHtmlModal = true
            }">规则</a></p>
        <p><a @click="goService(detail.kefu_url)">客服</a></p>
        <p><router-link to="/duanju/automaticRefund" v-if="is_open_refund">自动退款</router-link></p>
      </div>
      <div class="pic">
        <img class="red_bag" :src="pic" width="100%"/>
        <div class="pic_money"><img :src="pic_1" width="100%"/></div>
        <div class="money_text">{{json.money}}元</div>
      </div>

      <van-swipe style="height:40px;" vertical :autoplay="3000" :show-indicators="false" class="logsWrap">
        <van-swipe-item v-for="(item,key) in logsList" :key="key">
          <van-row class="item" type="flex" align="center" justify="space-between">
            <van-col class="avatar"><img :src="(item.avatar=='' || item.avatar==null)?noheadimg:item.avatar" class="headimg"/><span>{{item.name}} {{item.time}}</span></van-col>
            <van-col class="desc">已经领取看短剧<span class="font-color-red">抽红包福利</span></van-col>
          </van-row>
        </van-swipe-item>
      </van-swipe>

      <div class="paybtn"><van-button round block @click="onsubmit">确认领取</van-button></div>

      <div class="payList" v-if="payList.length">
        <van-radio-group v-model="json.payWay">
          <van-cell-group :border="false">
            <van-cell v-for="item in payList" :key="item.key">
              <template #icon><img :src="item.img" height="20"/></template>
              <template #title>
                {{item.name}}   <span style="color: red;">随机立减，最高免单</span>
              </template>
              <template #right-icon>
                <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div class="foot">
        <p class="video_name">{{siteConfig.wechat_name}}</p>
        <p>{{siteConfig.name}}</p>
        <p>{{siteConfig.icp}}</p>
        <p>{{siteConfig.address}}</p>
        <p>客服电话：<a :href="'tel:'+siteConfig.phone">{{siteConfig.phone}}</a></p>
      </div>

    </div>


    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="content_main">
        <div class="title">活动规则</div>
        <div class="body" v-html="contentHtml"></div>
      </div>
      <div class="action">
        <van-button type="default" round @click="()=>{contentHtmlModal = false}">我知道了</van-button>
        <van-button type="primary" round @click="onConfirm" class="confirm">确认领奖</van-button>
      </div>
    </van-popup>

    <van-popup v-model="paySuccessModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="PayStatus">
      <div class="success" v-if="paysuccess==1">
        <div><van-icon name="checked" size="100" color="#07c160"/></div>
        <div class="h4">支付成功</div>
        <div class="mt10 count-down">
          <van-count-down :time="paytime" @finish="finishPay">
            <template #default="timeData">
              <span class="block">{{ timeData.seconds }}</span>
              <span>S 后跳转</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="fail" v-if="paysuccess==0">
        <div class="h4">恭喜您</div>
        <div class="h5">获得<span>限时降价</span>资格</div>
        <div class="price"><label>¥<em>{{detail.sec_money | keepTwoNum}}</em></label></div>
        <div class="old_price">原价¥{{detail.money | keepTwoNum}}</div>
        <div class="mt20 btn"><van-button round color="#ff0000" block @click="onSubmitPay(0)">¥{{detail.sec_money | keepTwoNum}} 立即抢购</van-button><span class="tips">省 {{(detail.money - detail.sec_money | keepTwoNum).toFixed(2)}} 元</span></div>
        <div class="mt20 btn"><van-button round color="#ff0000" plain block @click="onSubmitPay(1)">我已付款</van-button></div>
      </div>
    </van-popup>

  </div>

</template>
<script>
  import {keepTwoNum,getordernum} from '@/utils/utils'
  import {GetPayConfig,CreateOrder, GetOrderStatus} from '../services/services'
  export default {
    name:'duanju',
    components: {},
    data () {
      return {
        pic:'',
        pic_1:'https://image.youzuanmy.vip/image/zdd/show.jpg',
        sessionKey:'duanju_order',
        contentHtml:'',
        logsList:[
          {avatar:'https://thirdwx.qlogo.cn/mmopen/vi_32/DDyXhPqm6Z4hAOqP7NoATGEpI3LChFxG7Kn3BW128eOia4E0hZAUB6n7VXOYrYHrCOEkIWLSaBd3xO7hMuJYCibQ/132',name:'一**'},
          {avatar:'https://wx.qlogo.cn/mmopen/vi_32/pic52MIPESpQQlVWM5rhBs4jo5dJzfFDXVF0mt8UTLQOibXAQhBAeNjHibKGHzDpDFrRxSzBRkdKZw5pKjVxq84CQ/132',name:'幸**'},
          {avatar:'https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83erL7kVKkKKdLJicWuSox1WG2n1T5dORcQy4TEET2lNzsemec5TEZ6cyVRuQB9eibzXfiahMBgQxj8W6g/132',name:'心**'},
          {avatar:'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL3PIyficCu7b4iaDYGfPoLuNVZyyJibPXJHfgWCNbR7mIFQaBO5KrnyPWHtwAGicbbWsdf5hgp13Te3g/132',name:'歌**'},
          {avatar:'https://wx.qlogo.cn/mmopen/vi_32/tQTicYLeq4iclNgWyQkMOruicIh5LB87yelPoujOFibgwqymua7fZpctdtbwfWVLXTS3giaQxet9Ylxzl5eicD7nqFWA/132',name:'Sun**'},
        ],
        detail:{},
        payList:[],
        json:{
          money:'',
          type:'',
          order_sn:'',
          payWay:11,
          url:location.href
        },
        paySuccessModal:false,
        paysuccess:0,
        paytime:3 * 1000,
        count:0,
        maxcount:5,
        timer:null,
        contentHtmlModal:false,
        is_open_refund:false,
        siteConfig:{},
        formAction: '',
        formInputs: []
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
    },
    filters:{
      keepTwoNum
    },
    computed:{
    },
    mounted(){
      window.addEventListener(
        "pageshow", (event)=> {
          if(event.persisted || window.performance && window.performance.navigation.type == 2){
            location.reload()
            return false
          }
        },
        false
      )

      setTimeout(()=>{
        if(sessionStorage.getItem(this.sessionKey) && this.json.order_sn == ''){
          let order_info = JSON.parse(sessionStorage.getItem(this.sessionKey))
          this.json={
            ...this.json,
            ...order_info
          }
        }

        if(this.json.order_sn != ''){
          this.findOrderStatus()
        }
      },1000)
      this.findpayConfig()
    },
    methods:{
      goService(url){
        location.href=url
      },
      findpayConfig(){
        GetPayConfig({type:this.json.type}).then(result => {
          if(result.data.code==1){
            const {payConfig,companyInfo,money,type} = result.data.data
            this.detail = result.data.data
            this.siteConfig = companyInfo
            this.pic = this.oss+(type==888?'888-1.jpg':'188-1.jpg')

            this.payList=payConfig
            this.json = {
              ...this.json,
              money:money
            }
            this.is_open_refund = this.detail.is_open_refund

          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      onConfirm(){
        this.contentHtmlModal = false
        this.onsubmit()
      },
      onsubmit(){
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        if(this.json.order_sn == ''){
          this.json.order_sn = order_sn
        }
        this.getCreateOrder({
          ...this.json,
          order_sn:order_sn
        })
      },
      parseFormData(formDataStr) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(formDataStr, 'text/html');
        const formElement = doc.querySelector('form');
        this.formAction = formElement.getAttribute('action');
        const inputElements = formElement.querySelectorAll('input');
        inputElements.forEach(input => {
          if (input.type === 'hidden') {
            this.formInputs.push({
              name: input.getAttribute('name'),
              value: input.getAttribute('value')
            });
          } else if (input.type === 'submit') {
            // 这里可以处理提交按钮相关逻辑，比如记录是否有提交按钮等，当前示例暂不做特殊处理
          }
        });
      },
      getCreateOrder(res){
        CreateOrder(res).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem(this.sessionKey,JSON.stringify(res))
            const {data} = result.data
            let payWay = res.payWay
            console.log(payWay,data)
            if(payWay==14){
              location.href=data
            }else{
              this.parseFormData(data)
              const form = document.createElement('form');
              form.setAttribute('action', this.formAction);
              form.setAttribute('method', 'post');
              this.formInputs.forEach(input => {
                const newInput = document.createElement('input');
                newInput.setAttribute('type', input.type);
                newInput.setAttribute('name', input.name);
                newInput.setAttribute('value', input.value);
                form.appendChild(newInput);
              });
              document.body.appendChild(form);
              form.submit();
            }
          }else{
            this.$toast.fail(result.data.msg)
          }
        })



        // CreateOrder(res).then(result => {
        //   if(result.data.code==1){
        //     sessionStorage.setItem(this.sessionKey,JSON.stringify(res))
        //
        //     setTimeout(()=>{
        //       location.href=result.data.data
        //     },1000)
        //
        //     setTimeout(()=>{
        //       this.findOrderStatus()
        //     },3000)
        //   }else{
        //     this.$toast.fail(result.data.msg)
        //   }
        // })
      },
      findOrderStatus(){
        this.paySuccessModal=true
        GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = 1
            this.clearTime()
          }else{
            this.paysuccess = 0
            if(this.timer == null && this.count<this.maxcount){
              this.getTimerStatus()
            }
          }
          // if(code==1){
          //   this.$toast.success('支付成功')
          //   this.clearTime()
          //   setTimeout(()=>{
          //     this.finishPay()
          //   },1000)
          // }else{
          //   if(status){
          //     this.$toast.success(result.data.msg)
          //     this.clearTime()
          //     return
          //   }
          //   this.$dialog.confirm({
          //     title: '提示',
          //     message: '确认是否已完成支付',
          //     confirmButtonText:'已完成',
          //     cancelButtonText:'未支付',
          //     closeOnClickOverlay:true
          //   }).then(() => {//已支付完成检测
          //     this.findOrderStatus(true)
          //   }).catch((res) => {
          //     if(res == 'overlay'){//关闭弹层
          //       this.clearTime()
          //     }else{//重新支付
          //       this.getCreateOrder(this.json)
          //     }
          //   })
          //   if(this.timer == null && this.count<this.maxcount){
          //     this.getTimerStatus()
          //   }
          // }
        })
      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=this.maxcount){
            console.log(123)
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },1*1000)
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem(this.sessionKey)
      },
      finishPay(){
        const {wechat_service_url,kefu_url,money} = this.detail
        this.$router.push({path:'/duanju/success',query:{wechat_url:escape(wechat_service_url),kefu_url:escape(kefu_url),money:money,order_sn:this.json.order_sn}})
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.findOrderStatus()
            break;
          case 0:
            this.json.money = this.detail.sec_money
            this.getCreateOrder(this.json)
            break;
        }
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:#d03515 url('@{static-dir}xin-bj-39.9.png?v02') no-repeat;background-size:100% auto;padding:86px 32px 0px;
    .float_right{top:24px;
      p{padding:8px 0px;line-height:14px;
        a{background-color:rgba(222, 19, 19, 0.2);color: #fff;border-radius:8px 0 0 8px;font-size:12px;width:20px;text-align:center;display:inline-block;padding:8px 0px;}
      }
    }
    .pic{
      position: relative;
      .red_bag{
        width:323px;
        height:250px;
      }
      .pic_money{
        position: absolute;
        right: 0;
        top: 60px;
        width: 100px;
        height: 100px;
      }
      .money_text {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        text-align: center;
        width: 100px;
        color: #fef7ce;
        font-size: 20px;
        font-weight: 700;
        text-shadow: 0 1px red, 1px 0 red, -1px 0 red, 0 -1px red;
      }
    }
    .logsWrap{margin-top:128px;
      .item{font-size:12px;padding-top:10px;color:#666;
        .avatar{display:flex;align-items:center;
          .headimg{width:20px;height:20px;border-radius:100%;display:block;margin-right:10px;}
        }
        .desc{}
      }
    }
    .paybtn{height:66px;margin-top:14px;
      .van-button{width:88%;background:linear-gradient(to bottom, #fe7a4d 0%, #f71c1e 100%);height:58px;line-height:58px;font-size:22px;color:#fff;animation:2s ease-in-out 0s infinite normal none running scaleDraw;margin:auto;border:none;}
    }
    .payList{margin-top:14px;
      .van-cell{padding:0px;}
    }

  }

  .foot{color:#fff;padding-bottom:24px;text-align:center;font-size:14px;margin-top:46px;
    p{padding:2px 0px;}
  }
  .contentPop{width:80%;background:#fff7e8;
    .action{justify-content:space-between;
      .van-button{padding:0px 24px;height:36px;line-height:36px;
        &.confirm{background: linear-gradient(to right, #f7ddac 60%, #cbab6f);color: #7f4d19;border:none;}
      }
    }
  }
  @keyframes scaleDraw{
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
  }
  .PayStatus{width:88%;border-radius:6px;
    .success{padding:30px;text-align:center;
      .h4{font-size:20px;font-weight:700;margin-top:10px;}
      .count-down{
        span{color:#999;}
      }
    }
    .fail{padding:20px 20px 30px 20px;text-align:center;
      .h4{font-size:24px;font-weight:700;}
      .h5{margin-top:10px;font-size:16px;color:#666;
        span{color:#ed5b42;}
      }
      .price{color:#ed5b42;margin-top:10px;
        em{font-size:40px;}
      }
      .old_price{font-size:12px;color:#999;text-decoration:line-through;}
      .btn{position:relative;
        .tips{background:#2a9dfc;border-radius:60px 60px 60px 0px;position:absolute;right:0px;top:-26px;z-index:9;color:#fff;padding:4px 14px;display:block;}
      }

    }
  }
</style>
